module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Лазерные станки: услуги и комплектующие || Инфра-Центр","short_name":"Инфра-Центр","start_url":"/","background_color":"#343a40","theme_color":"#343a40","display":"minimal-ui","icon":"src/images/infra-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8f0fe558bf5e3d8361b4c24a34a5e217"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-160636458-1","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"siteSpeedSampleRate":10,"exclude":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
