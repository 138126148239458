// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-cutting-infracut-co-2-js": () => import("./../../../src/pages/cutting/infracut-co2.js" /* webpackChunkName: "component---src-pages-cutting-infracut-co-2-js" */),
  "component---src-pages-cutting-infracut-cub-js": () => import("./../../../src/pages/cutting/infracut-cub.js" /* webpackChunkName: "component---src-pages-cutting-infracut-cub-js" */),
  "component---src-pages-cutting-infracut-f-js": () => import("./../../../src/pages/cutting/infracut-f.js" /* webpackChunkName: "component---src-pages-cutting-infracut-f-js" */),
  "component---src-pages-cutting-infracut-l-js": () => import("./../../../src/pages/cutting/infracut-l.js" /* webpackChunkName: "component---src-pages-cutting-infracut-l-js" */),
  "component---src-pages-cutting-infracut-lct-js": () => import("./../../../src/pages/cutting/infracut-lct.js" /* webpackChunkName: "component---src-pages-cutting-infracut-lct-js" */),
  "component---src-pages-cutting-infracut-t-6000-js": () => import("./../../../src/pages/cutting/infracut-t6000.js" /* webpackChunkName: "component---src-pages-cutting-infracut-t-6000-js" */),
  "component---src-pages-cutting-infracut-t-js": () => import("./../../../src/pages/cutting/infracut-t.js" /* webpackChunkName: "component---src-pages-cutting-infracut-t-js" */),
  "component---src-pages-cutting-speeds-table-js": () => import("./../../../src/pages/cutting-speeds-table.js" /* webpackChunkName: "component---src-pages-cutting-speeds-table-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marking-inframark-3-d-js": () => import("./../../../src/pages/marking/inframark-3d.js" /* webpackChunkName: "component---src-pages-marking-inframark-3-d-js" */),
  "component---src-pages-marking-inframark-af-js": () => import("./../../../src/pages/marking/inframark-af.js" /* webpackChunkName: "component---src-pages-marking-inframark-af-js" */),
  "component---src-pages-marking-inframark-co-2-js": () => import("./../../../src/pages/marking/inframark-co2.js" /* webpackChunkName: "component---src-pages-marking-inframark-co-2-js" */),
  "component---src-pages-marking-inframark-color-js": () => import("./../../../src/pages/marking/inframark-color.js" /* webpackChunkName: "component---src-pages-marking-inframark-color-js" */),
  "component---src-pages-marking-inframark-cub-js": () => import("./../../../src/pages/marking/inframark-cub.js" /* webpackChunkName: "component---src-pages-marking-inframark-cub-js" */),
  "component---src-pages-marking-inframark-fly-js": () => import("./../../../src/pages/marking/inframark-fly.js" /* webpackChunkName: "component---src-pages-marking-inframark-fly-js" */),
  "component---src-pages-marking-inframark-fs-js": () => import("./../../../src/pages/marking/inframark-fs.js" /* webpackChunkName: "component---src-pages-marking-inframark-fs-js" */),
  "component---src-pages-marking-inframark-ft-js": () => import("./../../../src/pages/marking/inframark-ft.js" /* webpackChunkName: "component---src-pages-marking-inframark-ft-js" */),
  "component---src-pages-marking-inframark-mini-js": () => import("./../../../src/pages/marking/inframark-mini.js" /* webpackChunkName: "component---src-pages-marking-inframark-mini-js" */),
  "component---src-pages-marking-inframark-p-js": () => import("./../../../src/pages/marking/inframark-p.js" /* webpackChunkName: "component---src-pages-marking-inframark-p-js" */),
  "component---src-pages-marking-inframark-uv-js": () => import("./../../../src/pages/marking/inframark-uv.js" /* webpackChunkName: "component---src-pages-marking-inframark-uv-js" */),
  "component---src-pages-special-infracentr-3-d-js": () => import("./../../../src/pages/special/infracentr-3d.js" /* webpackChunkName: "component---src-pages-special-infracentr-3-d-js" */),
  "component---src-pages-special-infracut-20-t-js": () => import("./../../../src/pages/special/infracut-20t.js" /* webpackChunkName: "component---src-pages-special-infracut-20-t-js" */),
  "component---src-pages-special-infracut-qcw-js": () => import("./../../../src/pages/special/infracut-qcw.js" /* webpackChunkName: "component---src-pages-special-infracut-qcw-js" */),
  "component---src-pages-special-infralcs-js": () => import("./../../../src/pages/special/infralcs.js" /* webpackChunkName: "component---src-pages-special-infralcs-js" */),
  "component---src-pages-special-infrascribe-js": () => import("./../../../src/pages/special/infrascribe.js" /* webpackChunkName: "component---src-pages-special-infrascribe-js" */),
  "component---src-pages-welding-infraweld-150-j-js": () => import("./../../../src/pages/welding/infraweld-150j.js" /* webpackChunkName: "component---src-pages-welding-infraweld-150-j-js" */),
  "component---src-pages-welding-infraweld-h-js": () => import("./../../../src/pages/welding/infraweld-h.js" /* webpackChunkName: "component---src-pages-welding-infraweld-h-js" */),
  "component---src-pages-welding-infraweld-jw-js": () => import("./../../../src/pages/welding/infraweld-jw.js" /* webpackChunkName: "component---src-pages-welding-infraweld-jw-js" */),
  "component---src-pages-welding-infraweld-qcw-js": () => import("./../../../src/pages/welding/infraweld-qcw.js" /* webpackChunkName: "component---src-pages-welding-infraweld-qcw-js" */)
}

